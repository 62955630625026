/**
 * Google Analytics events
 */
const gtagEvents = {
  open_buy_online: 'open_buy_online',
  open_credit_application: 'open_credit_application',
  open_job_application: 'open_job_application',
  generate_lead: 'generate_lead',
  print_page: 'print_page',
  search: 'search',
  share: 'share',
  view_brochure: 'view_brochure',
}

module.exports = gtagEvents
