const { gtag } = require('@hutson/utils')

exports.onClientEntry = () => {
  if (!window.crypto) {
    window.crypto = window.msCrypto
  }
  if (process.env.NODE_ENV === 'production') {
    gtag('config', process.env.GATSBY_GOOGLE_ANALYTICS_ID, {
      currency: 'USD',
      linker: {
        domains: ['hutsoninc.com', 'build.hutsoninc.com', 'fieldguide.hutsoninc.com'],
      },
      send_page_view: false,
    })
  }
}
