exports.onRouteUpdate = ({ location, prevLocation }) => {
  if (
    process.env.NODE_ENV !== 'production' ||
    typeof window.rr_priority_prospects !== 'object' ||
    prevLocation === null ||
    !/used-equipment/.test(location.pathname)
  ) {
    return null
  }

  try {
    window.rr_priority_prospects.log_pageview()
  } catch (err) {
    console.error(err)
  }

  return null
}
