/**
 * Facebook Pixel events
 */
const fbqEvents = {
  OpenJobApplication: 'OpenJobApplication',
  Lead: 'Lead',
  PrintPage: 'PrintPage',
  Search: 'Search',
  Share: 'Share',
  ViewBrochure: 'ViewBrochure',
}

module.exports = fbqEvents
