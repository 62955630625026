const isBrowser = require('./is-browser')
const noop = require('./noop')

/**
 * Google Analytics `gtag` function
 * @type {Function}
 * @see {@link https://developers.google.com/tag-platform/gtagjs/reference}
 */
const gtag = isBrowser && window.gtag ? window.gtag : noop

module.exports = gtag
