const isBrowser = require('./is-browser')
const noop = require('./noop')

/**
 * Meta Pixel `fbq` function
 * @type {Function}
 * @see {@link https://developers.facebook.com/docs/meta-pixel/reference}
 */
const fbq = isBrowser && window.fbq ? window.fbq : noop

module.exports = fbq
